import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'tekmud-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [RouterModule, CommonModule],
})
export class NavbarComponent {
  menuItems = [
    { name: 'Builder', link: '/builder' },
    { name: 'Viewer', link: '/viewer' },
    { name: 'Page', link: '/page' },
  ];
}
