<ul class="horizontal">
  <li>
    <a [routerLink]="'/'"> Tekmud </a>
  </li>
  <li
    *ngFor="let menuItem of menuItems"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: false }"
  >
    <a [routerLink]="menuItem.link">
      {{ menuItem.name }}
    </a>
  </li>
</ul>
