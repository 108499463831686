import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from '@tekmud/core/ui/components/navbar';
// import { buttonWithStyle, HocComponentsModule } from '@tekmud/hoc/components';

@Component({
  standalone: true,
  selector: 'tekmud-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NavbarComponent,
    RouterModule,
    // buttonWithStyle,
    // HocComponentsModule,
  ],
})
export class AppComponent {}
