import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'builder',
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('@tekmud/builder-feature-builder').then(
            (m) => m.BuilderComponent
          ),
      },
      { path: '**', redirectTo: 'new' },
    ],
  },
  {
    path: 'viewer',
    loadComponent: () =>
      import('@tekmud/builder/feature/viewer').then((m) => m.ViewerComponent),
    pathMatch: 'full',
  },
  {
    path: 'page',
    loadComponent: () =>
      import('@tekmud/page/feature/list').then((m) => m.ListComponent),
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'builder',
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class ShellModule {}
